export const emailAddressRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

// Matches positive numbers, both integers ("1") and decimal numbers ("1.1").
// Leading and trailing whitespace is allowed.
// First digit can't be "0" as in "01" or "01.2".
// "1." is not allowed, must be "1" or "1.0".
export const positiveNumberRegex = /^\s*[1-9][0-9]*(.[0-9]+)?\s*$/;

// Matches positive integers (i.e. does not include zero).
// Leading and trailing whitespace is allowed.
// First digit can't be "0" as in "01".
export const positiveIntegerRegex = /^\s*[1-9][0-9]*\s*$/;

export const NoSpacesRegex = /^\S*$/;

/**
 * Primarily for use with forms to avoid a string input being submittable
 * with only " "
 */
export const stringIsNotOnlyWhiteSpaceRegex = /.*\S.*/;

export const ecNumberRegex = /^$|^[0-9]{3}-[0-9]{3}-[0-9]{1}$/;

export const casNumberRegex = /^$|^[0-9]{2,7}-[0-9]{2}-[0-9]{1}$/;

export const inchiKeyRegex = /^$|^[A-Z]{14}-[A-Z]{10}-[A-Z]{1}$/;

export const documentNameRegex = /^[\w\-.\s]+\.[a-zA-Z]{3,4}$/;

/**
 * Strips non-alphanumeric characters (except spaces) from a string
 * Example: "Adwel® 1665A" becomes "Adwel 1665A"
 */
export const stripNonAlphanumericRegex = /[^a-zA-Z0-9\s]/g;

// Usage example:
// const cleanString = dirtyString.replace(stripNonAlphanumericRegex, '');
