import type { ColumnDef } from "@tanstack/react-table";
import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";
import useSWR from "swr";
import { useAuthContext } from "../../../../components/Auth";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
  SecondaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import { ConfirmDialog } from "../../../../components/ConfirmDialog/ConfirmDialog";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { Row } from "../../../../components/Layout/Layout";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { RadioButton } from "../../../../components/RadioButton/RadioButton";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../components/Table/Table";
import type { SortingRule } from "../../../../components/Table/utils";
import {
  H3,
  SmallSectionHeaderRegular,
} from "../../../../components/Typography/Typography";
import { endpoints } from "../../../../endpoints";
import { RadioButtonContainer } from "../../../../layout/FormLayout";
import { ContentWrapper } from "../../../../layout/publicPageLayout";
import type { DataMutate, WithPagination } from "../../../../types/types";
import type {
  ListPriceVisibility,
  PIMProduct,
  PIMProductBase,
  ProductListPriceSchema,
  ProductListPriceUpdateSchema,
} from "../../../../types/types.PIM";
import {
  defaultHandleSort,
  formatDate,
  TablePlaceholder,
  useStoreState,
  useUpdateProductStatus,
} from "../../../../util/util";
import { AddOrEditProductSkuListPrice } from "./AddOrEditProductSkuListPrice";

const HeaderSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
`;

const RadioSection = styled(RadioButtonContainer)`
  margin-left: -5px;
`;

const HeaderRow = styled(Row)`
  align-items: flex-end;
  margin: 16px 0;
  .place_over {
    z-index: 1;
  }
`;

type TableData = ProductListPriceSchema & { sku_name: string };

export type OnCompleteArgs = {
  list_price_number?: string;
  sku_number: string;
  patch_data: ProductListPriceUpdateSchema | undefined;
  post_data: ProductListPriceUpdateSchema[];
};

export const ProductPricingTab = ({
  product,
  mutate_product,
  replace_product_id,
}: {
  product: PIMProduct;
  mutate_product: DataMutate<PIMProduct>;
  replace_product_id: (id: string) => void;
}) => {
  const [pricing_visibility, set_pricing_visibility] =
    useState<ListPriceVisibility>(product.list_price_visibility);
  const [transient_pricing_visibility, set_transient_pricing_visibility] =
    useState<ListPriceVisibility>();
  const [visibility_dialog_message, set_manage_visibility_dialog_message] =
    useState<string>("");
  const [show_visibility_dialog, set_show_visibility_dialog] =
    useState<boolean>(false);
  const [is_updating_product, set_is_updating_product] = useState(false);
  const [per_page, set_per_page] = useState(10);
  const [offset, set_offset] = useState(0);
  const [sorting_rules, set_sorting_rules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({ orderBy: "asc" });
  const [table_data, set_table_data] = useState<TableData[]>([]);
  const [table_pagination, set_table_pagination] = useState({
    perPage: per_page,
    pageCount: 0,
    pageIndex: 0,
  });
  const [list_price_to_edit, set_list_price_to_edit] =
    useState<ProductListPriceSchema>();
  const [show_add_or_edit_product_list, set_show_add_or_edit_product_list] =
    useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { hasPermission } = useAuthContext();
  const { notifyError, notifySuccess } = useNotifications();

  const update_product_status = useUpdateProductStatus({ product });

  const {
    data: product_list_prices,
    error: product_list_prices_error,
    mutate: mutate_product_list_prices,
  } = useSWR<WithPagination<{ data: ProductListPriceSchema[] }>>([
    endpoints.v2_tenants_tenant_id_products_product_id_list_prices(
      tenant_id,
      product.id
    ),
    useMemo(
      () => ({
        params: {
          offset,
          limit: per_page,
          order_by: sorting_rules.orderBy || "asc",
          ...(sorting_rules.sortBy ? { sort_by: sorting_rules.sortBy } : {}),
        },
      }),
      [offset, per_page, sorting_rules.orderBy, sorting_rules.sortBy]
    ),
  ]);

  const on_delete_list_price = useCallback(
    (list_price: ProductListPriceSchema) => {
      const on_delete = async () => {
        set_is_updating_product(true);
        try {
          const { data: updated_product } = await update_product_status();
          await axios.delete(
            endpoints.v2_tenants_tenant_id_products_product_id_sku_sku_id_list_prices_list_prices_id(
              tenant_id,
              updated_product.id,
              list_price.sku_number,
              list_price.number
            )
          );
          if (
            updated_product.display_status === "staged" ||
            updated_product.display_status === "unpublished_staged"
          ) {
            const product_id =
              updated_product.product_number ??
              updated_product.primary_staged_product_id ??
              updated_product.id;
            await replace_product_id(product_id);
          }
          await mutate_product_list_prices();
          notifySuccess(t("List price removed successfully"));
        } catch (error) {
          const message = (error as AxiosError)?.response?.data?.message
            ? (error as AxiosError)?.response?.data?.message
            : t("Failed to remove list price, please try again later.");
          notifyError(message, { error });
        } finally {
          set_is_updating_product(false);
        }
      };
      on_delete();
    },
    [
      mutate_product_list_prices,
      notifyError,
      notifySuccess,
      replace_product_id,
      t,
      tenant_id,
      update_product_status,
    ]
  );

  const confirm_product_list_pricing_visibility = async (
    list_price_visibility: ListPriceVisibility
  ) => {
    set_is_updating_product(true);
    try {
      const { data: updated_product } = await update_product_status();
      const { data } = await axios.post<
        ListPriceVisibility,
        AxiosResponse<PIMProductBase>
      >(
        `/v2/tenants/${tenant_id}/pim/products/${updated_product.id}/list-price-visibility`,
        { list_price_visibility }
      );
      if (
        data.display_status === "staged" ||
        data.display_status === "unpublished_staged"
      ) {
        const product_id =
          data.product_number ?? data.primary_staged_product_id ?? data.id;
        await replace_product_id(product_id);
      } else {
        await mutate_product();
      }
      notifySuccess(t("List Price product visibility updated successfully"));
    } catch (error) {
      const message = (error as AxiosError)?.response?.data?.message
        ? (error as AxiosError)?.response?.data?.message
        : t(
            "Failed to update the product list price visibility, please try again later."
          );
      notifyError(message, { error });
    } finally {
      set_is_updating_product(false);
    }
  };

  const handle_sort = async (rules: SortingRule<ProductListPriceSchema>[]) => {
    defaultHandleSort(rules, sorting_rules, set_sorting_rules, set_table_data);
  };

  const change_per_page = (perPage: number) => {
    set_per_page(perPage);
    if (perPage > offset) {
      set_offset(0);
    }
  };

  const change_page = (offset: number) => {
    set_offset(offset);
    set_table_data([]);
  };

  const handle_add_list_price = () => {
    set_show_add_or_edit_product_list(true);
  };

  const on_close_slideout = () => {
    set_list_price_to_edit(undefined);
    set_show_add_or_edit_product_list(false);
  };

  const on_complete_add_or_edit = async (on_complete_props: OnCompleteArgs) => {
    const { list_price_number, sku_number, patch_data, post_data } =
      on_complete_props;
    set_is_updating_product(true);
    try {
      const { data: updated_product } = await update_product_status();
      const promises = [];
      if (patch_data && list_price_number) {
        promises.push(
          axios.patch(
            endpoints.v2_tenants_tenant_id_products_product_id_sku_sku_id_list_prices_list_prices_id(
              tenant_id,
              updated_product.id,
              sku_number,
              list_price_number
            ),
            patch_data
          )
        );
      }
      if (post_data.length > 0) {
        promises.push(
          axios.post(
            endpoints.v2_tenants_tenant_id_products_product_id_sku_sku_id_list_prices(
              tenant_id,
              updated_product.id,
              sku_number
            ),
            { prices: post_data }
          )
        );
      }
      await Promise.all(promises);
      if (
        updated_product.display_status === "staged" ||
        updated_product.display_status === "unpublished_staged"
      ) {
        const product_id =
          updated_product.product_number ??
          updated_product.primary_staged_product_id ??
          updated_product.id;
        await replace_product_id(product_id);
      }
      await mutate_product_list_prices();
      on_close_slideout();
      notifySuccess(t("List Price saved successfully"));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not create or update list prices. Something went wrong."),
        {
          error,
        }
      );
    } finally {
      set_is_updating_product(false);
    }
  };

  const can_edit_product = useMemo(
    () =>
      product.is_editable &&
      product.status !== "archived" &&
      hasPermission("modify_products"),
    [hasPermission, product.is_editable, product.status]
  );

  const tooltip_message = useMemo(() => {
    if (!product.is_editable) {
      return t(
        "This product is assigned to 1 or more teams and cannot be edited"
      );
    }
    if (product.status === "archived") {
      return t("This product is archived and cannot be edited");
    }
    if (!hasPermission("modify_products")) {
      return t("You do not have the required permission to update list prices");
    }
  }, [hasPermission, product.is_editable, product.status, t]);

  const table_columns = useMemo<ColumnDef<TableData>[]>(
    () => [
      {
        header: t("SKU"),
        accessorKey: "sku_name",
        enableSorting: false,
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Min. No. of Units"),
        accessorKey: "minimum_units",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Min. Order Quantity"),
        accessorKey: "quantity",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Price ($/UoM)"),
        accessorKey: "price",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: t("Valid Until"),
        accessorKey: "valid_until",
        cell: (cell) => cell.renderValue(),
      },
      {
        header: "",
        accessorKey: " ",
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <>
            {can_edit_product ? (
              <div style={{ display: "flex", gap: "8px" }}>
                <EditButton
                  disabled={is_updating_product}
                  onClick={() => {
                    set_list_price_to_edit(original);
                    set_show_add_or_edit_product_list(true);
                  }}
                />
                <ButtonWithConfirmDialog
                  Button={DeleteButton}
                  disabled={is_updating_product}
                  testid={"remove-list-price-from-product"}
                  handleConfirm={() => on_delete_list_price(original)}
                  confirmMessage={t(
                    "Are you sure you want to remove this list price?"
                  )}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ],
    [can_edit_product, is_updating_product, on_delete_list_price, t]
  );

  useEffect(() => {
    const handle_pricing_data = ({
      data,
      pagination,
    }: WithPagination<{ data: ProductListPriceSchema[] }>) => {
      set_table_data(
        data
          .filter(({ sku }) => sku)
          .reduce<TableData[]>(
            (acc, { valid_until, sku, ...rest }) => [
              ...acc,
              {
                ...rest,
                sku,
                valid_until: formatDate(valid_until),
                sku_name: sku
                  ? `${sku.packaging_type.name} (${sku.package_volume} ${sku.packaging_unit.name})`
                  : "",
              },
            ],
            []
          )
      );
      set_table_pagination({
        perPage: per_page,
        pageCount: Math.ceil(pagination.total / per_page),
        pageIndex: pagination.offset / per_page + 1,
      });
    };
    if (product_list_prices) {
      handle_pricing_data(product_list_prices);
    }
  }, [per_page, product_list_prices]);

  useEffect(() => {
    set_pricing_visibility(product.list_price_visibility);
  }, [product.list_price_visibility]);

  const is_loading = !product_list_prices && !product_list_prices_error;

  return (
    <>
      <>
        <HeaderSection>
          <H3 style={{ marginBottom: 0 }}>{t("Manage Visibility")}</H3>
          <SmallSectionHeaderRegular style={{ margin: 0 }}>
            {t("Control visibility of your product pricing")}
          </SmallSectionHeaderRegular>
        </HeaderSection>
        <RadioSection>
          <div
            data-for="manage_visibility_everywhere"
            data-tip={can_edit_product ? "" : tooltip_message}
          >
            <RadioButton
              name={"manage_visibility"}
              disabled={is_updating_product || !can_edit_product}
              value="available_everywhere"
              checked={pricing_visibility === "available_everywhere"}
              optionTitle={t("Available Everywhere")}
              handleChange={() => {
                set_manage_visibility_dialog_message(
                  t(
                    "Are you sure you want all users to see this product pricing?"
                  )
                );
                set_transient_pricing_visibility("available_everywhere");
                set_show_visibility_dialog(true);
              }}
            />
            <ReactTooltip id="manage_visibility_everywhere" effect="solid" />
          </div>
          <div
            data-for="manage_visibility_internal_and_customers"
            data-tip={can_edit_product ? "" : tooltip_message}
          >
            <RadioButton
              name={"manage_visibility"}
              value="internal_and_customers"
              disabled={is_updating_product || !can_edit_product}
              checked={pricing_visibility === "internal_and_customers"}
              optionTitle={t("Internal Users and Customers")}
              handleChange={() => {
                set_manage_visibility_dialog_message(
                  t(
                    "Are you sure you want only customers and internal users to see this product pricing?"
                  )
                );
                set_transient_pricing_visibility("internal_and_customers");
                set_show_visibility_dialog(true);
              }}
            />
            <ReactTooltip
              id="manage_visibility_internal_and_customers"
              effect="solid"
            />
          </div>
          <div
            data-for="manage_visibility_internal_users"
            data-tip={can_edit_product ? "" : tooltip_message}
          >
            <RadioButton
              name={"manage_visibility"}
              value="internal_users"
              disabled={is_updating_product || !can_edit_product}
              checked={pricing_visibility === "internal_users"}
              optionTitle={t("Internal Users")}
              handleChange={() => {
                set_manage_visibility_dialog_message(
                  t(
                    "Are you sure you want only internal users to see this product pricing?"
                  )
                );
                set_transient_pricing_visibility("internal_users");
                set_show_visibility_dialog(true);
              }}
            />
            <ReactTooltip
              id="manage_visibility_internal_users"
              effect="solid"
            />
          </div>
        </RadioSection>
      </>
      <HeaderRow>
        <HeaderSection style={{ marginBottom: 0 }}>
          <H3 style={{ marginBottom: 0 }}>{t("List Prices")}</H3>
          <SmallSectionHeaderRegular style={{ margin: 0 }}>
            {t("Set List Price for all customers")}
          </SmallSectionHeaderRegular>
        </HeaderSection>
        <DropDown
          items={[10, 20, 50]}
          activeItem={per_page}
          textLeft={t("items: ")}
          textRight={t("Per Page")}
          direction={"right"}
          className={"per_Page place_over"}
          clickHandler={change_per_page}
        />
      </HeaderRow>
      <ContentWrapper>
        <Table
          columns={table_columns}
          data={table_data}
          isLoading={is_loading}
          error={product_list_prices_error}
          handleSort={handle_sort}
          Placeholder={
            <TablePlaceholder
              message={
                !!product_list_prices_error
                  ? t(
                      "There was an error fetching list prices. Please try again later."
                    )
                  : t(
                      "No items to show. Please click the add button to add list prices."
                    )
              }
            />
          }
        />
        <Row style={{ justifyContent: "flex-end", marginTop: "16px" }}>
          <SecondaryButtonWithPlusIcon
            disabled={is_updating_product || !can_edit_product}
            onClick={handle_add_list_price}
            datafor="add-list-price"
            datatip={can_edit_product ? "" : tooltip_message}
          >
            <span style={{ fontSize: "15px" }}>{t("New list price")}</span>
          </SecondaryButtonWithPlusIcon>
          <ReactTooltip id="add-list-price" effect="solid" />
        </Row>
        <Pagination
          pagination={table_pagination}
          offset={offset}
          handlePageClick={change_page}
        />
      </ContentWrapper>
      <ConfirmDialog
        show={show_visibility_dialog}
        closeDialog={() => {
          set_show_visibility_dialog(false);
          set_transient_pricing_visibility(undefined);
        }}
        confirmMessage={visibility_dialog_message}
        handleConfirm={() => {
          set_pricing_visibility(transient_pricing_visibility!);
          set_show_visibility_dialog(false);
          confirm_product_list_pricing_visibility(
            transient_pricing_visibility!
          );
          set_transient_pricing_visibility(undefined);
        }}
      />
      <SlideOut
        show={show_add_or_edit_product_list}
        closeFlyout={on_close_slideout}
      >
        <AddOrEditProductSkuListPrice
          on_complete={on_complete_add_or_edit}
          list_price={list_price_to_edit}
          product={product}
        />
      </SlideOut>
    </>
  );
};
