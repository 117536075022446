import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { EditorToolbar, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import type { DefaultTheme } from "styled-components";
import styled from "styled-components";
import type { useForm } from "react-hook-form";
import { showErrors } from "../../util/util-components";
import { useTranslation } from "react-i18next";

interface IEditorProps {
  invalid: boolean;
  warn?: boolean;
  theme: DefaultTheme;
  isFocused?: boolean;
  readOnly?: boolean;
  disableList?: boolean;
  disableIndent?: boolean;
}

const EditorWrapper = styled.div<IEditorProps>`
  position: relative;
  overflow: hidden;
  background: ${({ theme, readOnly }) =>
    readOnly ? theme.disabledInput : theme.primaryBG};
  border-radius: 8px;
  margin-bottom: 18px;
  border: ${({ invalid, warn, theme, isFocused }) =>
    (invalid && `1px solid ${theme.errorColor}`) ||
    (warn && `1px solid ${theme.warningTextColor}`) ||
    (isFocused && !invalid && `1px solid ${theme.tertiaryBorder}`) ||
    `1px solid ${theme.primaryBorder}`};
  box-shadow: ${({ invalid, warn, theme, isFocused }) =>
    (invalid && `0px 0px 1px 1px ${theme.errorColor}`) ||
    (warn && `0px 0px 1px 1px ${theme.warningTextColor}`) ||
    (isFocused && !invalid && `0px 0px 1px 1px ${theme.tertiaryBorder}`) ||
    `0`};
  .quill {
    max-height: 100%;
  }
  .ql-container {
    border: 0;
    max-height: 100%;
  }

  .ql-editor {
    padding: ${({ readOnly }) => (readOnly ? "0 13px 13px" : `13px`)};
    min-height: 120px;
    /* max-height: 400px; */ // AD-4809 - Removed max-height.
  }

  .ql-editing {
    left: 8px !important;
  }

  .ql-toolbar {
    border: ${({ theme, readOnly }) =>
      readOnly ? "0" : `1px solid ${theme.primaryBorder}`};
    border-right: 0;
    border-left: 0;
    padding: ${({ readOnly }) => (readOnly ? "0" : "5px 0")};
    margin: 0 13px;
    button {
      border: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
      padding: 5px;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      border-radius: 4px;
      svg {
        float: none;
        text-align: center;
        fill: ${({ theme }) => theme.secondaryTextColor};
        height: 18px;
      }
      &.ql-active {
        border: ${({ theme }) => `1px solid ${theme.selectedBorder}`};
        background: ${({ theme }) => theme.secondaryButtonBG};
        svg {
          fill: ${({ theme }) => theme.secondaryButtonTextColor};
        }
      }
    }
  }

  .errorMessageWrapper {
    top: auto !important;
    bottom: -15px;
  }
`;

const FloatingLabel = styled.div<IEditorProps>`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme, invalid }) =>
    invalid ? theme.errorColor : theme.secondaryTextColor};
  pointer-events: none;
  padding: 13px 13px 8px;
`;

//Rich Text Editor component
export const RichEditor = ({
  value,
  name,
  label,
  placeholder,
  readOnly,
  onChange,
  useFormMethods: { errors, register, setValue, watch },
  disableList,
  disableIndent,
  id,
}: {
  value: string | undefined;
  name: string;
  label: string;
  placeholder?: string;
  readOnly?: boolean;
  disableList?: boolean;
  disableIndent?: boolean;
  id?: string;
  onChange?: (value: string) => void;
  useFormMethods: Pick<
    ReturnType<typeof useForm>,
    "register" | "errors" | "formState" | "setValue" | "watch"
  >;
}) => {
  const [state, setState] = useState(value);
  const [invalid, setInvalid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  // Watch for external changes to the form value
  const currentValue = watch(name);
  useEffect(() => {
    if (currentValue === "") {
      setState("<p><br></p>");
    }
  }, [currentValue]);

  const modules = {
    toolbar: {
      container: `#rich-${id ?? name}-editor-toolbar`,
    },
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };
  useEffect(() => {
    register(name, {});
    if (state && name) {
      if (state === "<p><br></p>") {
        setValue(name, "");
      } else {
        setValue(name, state);
      }
      if (onChange) {
        onChange(state);
      }
    }
  }, [state, setValue, name, onChange, register]);

  useEffect(() => {
    if (errors[name]?.message || errors[name]?.type) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [errors, name]);

  return (
    <>
      <EditorWrapper
        invalid={invalid}
        isFocused={isFocused}
        readOnly={readOnly}
      >
        <FloatingLabel invalid={invalid}>{label}</FloatingLabel>
        <EditorToolbar
          name={name}
          id={id}
          readOnly={readOnly}
          disableList={disableList}
          disableIndent={disableIndent}
        />
        <ReactQuill
          theme="snow"
          value={state || ""}
          onChange={(value) => setState(value)}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          readOnly={readOnly}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {showErrors({ errors, name, t })}
      </EditorWrapper>
    </>
  );
};
