import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../layout/portalPageLayout";
import { PageWrapper } from "../../../layout/portalPageLayout";
import { providePrivatePageProps } from "../../../util/Routing";
import { FullWidthHorizontalSeparator } from "../../../layout/portalPageLayout";
import styled, { useTheme } from "styled-components/macro";
import useSWR, { useSWRInfinite } from "swr";
import type {
  DashboardOtherUpdate,
  DashboardProductUpdate,
  DashboardSummary,
  DashboardUpdate,
  OptionType,
  WithPagination,
} from "../../../types/types";
import { endpoints } from "../../../endpoints";
import { useStoreState } from "../../../util/util";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../components/Error";
import { SoftHeaderMedium } from "../../../components/Typography/Typography";
import { H3DarkText } from "../../../components/Typography/Typography";
import { H5 } from "../../../components/Typography/Typography";
import { match } from "ts-pattern";
import { screenSize } from "../../../theme";
import { useCallback, useState } from "react";
import { DashboardProductUpdates } from "./DashboardProductUpdates";
import { DashboardOtherUpdates } from "./DashboardOtherUpdates";
import { useAuthContext } from "../../../components/Auth";
import { PAGE_SIZE } from "./dashboard.util";

const DashboardParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const DashboardSummarySection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`;

const DashboardSummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  border-radius: 4px;
  padding: 16px;
  flex: 1;
`;

const DashboardUpdatesSection = styled.section`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 32px;
  @media ${screenSize.mLarge} {
    width: 100%;
  }
  @media ${screenSize.medium} {
    flex-direction: column;
  }
`;

function constructQuery<T extends any>({
  baseUrl,
  previousPageData,
  index,
}: {
  baseUrl: string;
  previousPageData: WithPagination<{ data: T[] }> | null;
  index: number;
}) {
  if (previousPageData && previousPageData.data.length === 0) {
    return null;
  }
  const offset =
    index === 0 ? 0 : (previousPageData?.pagination.offset ?? 0) + PAGE_SIZE;
  const limit = PAGE_SIZE;
  const params = new URLSearchParams(`offset=${offset}&limit=${limit}`);
  return `${baseUrl}?${params}`;
}

export const Dashboard = providePrivatePageProps(({ user }) => {
  const [otherUpdates, setOtherUpdates] = useState<DashboardOtherUpdate[]>([]);
  const [productUpdates, setProductUpdates] = useState<
    DashboardProductUpdate[]
  >([]);
  const { storefront_id } = useStoreState();
  const { hasPermission } = useAuthContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: dashboardSummary, error: dashboardSummaryError } =
    useSWR<DashboardSummary>(
      endpoints.v2_storefronts_id_dashboard_summary(storefront_id)
    );
  const isSummaryLoading = !dashboardSummary && !dashboardSummaryError;

  const dashboardSummaryArr: OptionType<keyof DashboardSummary>[] = [
    { label: t("Total Active Products"), value: "total_active_products" },
    { label: t("Total Active Assets"), value: "total_active_assets" },
    { label: t("Total Active Customers"), value: "total_active_customers" },
    { label: t("Total Active Users"), value: "total_active_users" },
  ];

  const date = (() => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    } as Intl.DateTimeFormatOptions;
    const locale = Intl.DateTimeFormat().resolvedOptions().locale ?? "en-US";
    return new Intl.DateTimeFormat(locale, options).format(new Date());
  })();

  const getGreeting = (() => {
    const hours = new Date().getHours();
    return hours < 12
      ? t("Good morning")
      : hours < 18
      ? t("Good afternoon")
      : t("Good evening");
  })();

  const permissionAssignment = useCallback(
    (
      status: "archived" | "deleted" | "created" | "updated",
      permission:
        | "assets"
        | "attributes"
        | "templates"
        | "products"
        | "groups"
        | "collections"
        | "lists"
    ) =>
      status === "archived" || status === "deleted"
        ? hasPermission(`delete_${permission}`)
        : hasPermission([`view_${permission}`, `modify_${permission}`]),
    [hasPermission]
  );

  const hasPermissionToView = useCallback(
    (
      type: DashboardUpdate["type"],
      status: "archived" | "deleted" | "created" | "updated"
    ) => {
      try {
        return match(type)
          .with("assets", () => permissionAssignment(status, "assets"))
          .with("attribute", () => permissionAssignment(status, "attributes"))
          .with("collection", () => permissionAssignment(status, "collections"))
          .with("group", () => permissionAssignment(status, "groups"))
          .with("list", () => permissionAssignment(status, "lists"))
          .with("product", () => permissionAssignment(status, "products"))
          .with("template", () => permissionAssignment(status, "templates"))
          .exhaustive();
      } catch (error) {
        console.error(`Update type ${type} not expected`);
        return false;
      }
    },
    [permissionAssignment]
  );

  const {
    error: otherUpdatesError,
    setSize: setOtherUpdatesSize,
    size: otherUpdatesSize,
  } = useSWRInfinite<WithPagination<{ data: DashboardOtherUpdate[] }>>(
    (index, previousPageData) =>
      constructQuery({
        baseUrl:
          endpoints.v2_storefronts_id_dashboard_other_updates(storefront_id),
        previousPageData,
        index,
      }),
    {
      onSuccess: (data) => {
        const updates = data.flatMap(({ data }) =>
          data.filter(({ type, update_data: { status } }) =>
            hasPermissionToView(type, status)
          )
        );
        setOtherUpdates(updates);
      },
    }
  );

  const {
    error: productUpdatesError,
    setSize: setProductUpdatesSize,
    size: productUpdatesSize,
  } = useSWRInfinite<WithPagination<{ data: DashboardProductUpdate[] }>>(
    (index, previousPageData) =>
      constructQuery({
        baseUrl:
          endpoints.v2_storefronts_id_dashboard_product_updates(storefront_id),
        previousPageData,
        index,
      }),
    {
      onSuccess: (data) => {
        const updatedProducts = hasPermission([
          "view_products",
          "modify_products",
        ])
          ? data.flatMap(({ data }) => data)
          : [];
        setProductUpdates(updatedProducts);
      },
    }
  );

  return (
    <PageWrapper>
      <PageTitle>{t("Dashboard")}</PageTitle>
      <FullWidthHorizontalSeparator />
      <DashboardParentWrapper>
        <SoftHeaderMedium style={{ marginBottom: "8px" }}>
          {date}
        </SoftHeaderMedium>
        <H3DarkText
          style={{ margin: "0 0 32px" }}
        >{`${getGreeting}, ${user.firstname}`}</H3DarkText>
        {dashboardSummaryError ? (
          <ErrorPlaceholder
            message={t(
              "There was an error loading dashboard summary. Please try again later."
            )}
          />
        ) : isSummaryLoading ? (
          <DelayedSpinner />
        ) : dashboardSummary ? (
          <>
            <DashboardSummarySection style={{ marginBottom: "32px" }}>
              {dashboardSummaryArr.map(({ label, value }) => (
                <DashboardSummaryItem key={label}>
                  <H5 style={{ color: theme.navLinkColor }}>{label}</H5>
                  <H3DarkText style={{ margin: "8px 0" }}>
                    {dashboardSummary![value]}
                  </H3DarkText>
                </DashboardSummaryItem>
              ))}
            </DashboardSummarySection>
            {hasPermission(["delete_products", "modify_products"]) && (
              <DashboardSummarySection>
                <DashboardSummaryItem>
                  <H5 style={{ color: theme.navLinkColor }}>
                    {t("Total Product Completion")}
                  </H5>
                  <H3DarkText style={{ margin: "8px 0" }}>
                    {dashboardSummary.average_active_completion_score}%
                  </H3DarkText>
                </DashboardSummaryItem>
                <DashboardSummaryItem>
                  <H5 style={{ color: theme.navLinkColor }}>
                    {t("Published Product Completion")}
                  </H5>
                  <H3DarkText style={{ margin: "8px 0" }}>
                    {dashboardSummary.average_published_completion_score}%
                  </H3DarkText>
                </DashboardSummaryItem>
                {dashboardSummary.average_active_mandatory_completion_score !==
                  null && (
                  <DashboardSummaryItem>
                    <H5 style={{ color: theme.navLinkColor }}>
                      {t("Total Mandatory Completion")}
                    </H5>
                    <H3DarkText style={{ margin: "8px 0" }}>
                      {
                        dashboardSummary.average_active_mandatory_completion_score
                      }
                      %
                    </H3DarkText>
                  </DashboardSummaryItem>
                )}
                {dashboardSummary.average_published_mandatory_completion_score !==
                  null && (
                  <DashboardSummaryItem>
                    <H5 style={{ color: theme.navLinkColor }}>
                      {t("Published Mandatory Completion")}
                    </H5>
                    <H3DarkText style={{ margin: "8px 0" }}>
                      {
                        dashboardSummary.average_published_mandatory_completion_score
                      }
                      %
                    </H3DarkText>
                  </DashboardSummaryItem>
                )}
              </DashboardSummarySection>
            )}
          </>
        ) : null}
        <DashboardUpdatesSection>
          <DashboardProductUpdates
            data={productUpdates}
            error={productUpdatesError}
            setSize={setProductUpdatesSize}
            size={productUpdatesSize}
          />
          <DashboardOtherUpdates
            data={otherUpdates}
            error={otherUpdatesError}
            setSize={setOtherUpdatesSize}
            size={otherUpdatesSize}
          />
        </DashboardUpdatesSection>
      </DashboardParentWrapper>
    </PageWrapper>
  );
});
