import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SortingRule } from "react-table";
import styled from "styled-components";
import useSWR from "swr";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../components/Layout/Layout";
import { Pagination } from "../../../../components/Pagination/Pagination";
import {
  getProductStatusColor,
  getProductStatusText,
  StatusLeft,
} from "../../../../components/Status/Status";
import { Table } from "../../../../components/Table/Table";
import { endpoints } from "../../../../endpoints";
import { ContentWrapper } from "../../../../layout/portalPageLayout";
import type { WithPagination } from "../../../../types/types";
import type {
  PIMProductActivity,
  ProductStatusType,
} from "../../../../types/types.PIM";
import {
  defaultHandleSort,
  getDateTime,
  useStoreState,
} from "../../../../util/util";
import type { Getter } from "@tanstack/react-table";

type ModifiedType = {
  time: string;
  date: string;
};

type ActivityTableData = {
  id: string;
  version_number: string;
  status: string[];
  target_type: string[];
  target_id: string;
  last_modified_by: string[];
  modified_at: ModifiedType[];
  actions: { restore: boolean; view: boolean }[];
};

const RowBox = styled.div`
  height: 52px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

function transformData(data: PIMProductActivity[]) {
  return Object.values(
    data.reduce<Record<number, ActivityTableData>>(
      (
        acc,
        {
          target_id,
          modified_at,
          last_modified_by,
          id,
          target_type,
          version_number,
          status,
        }
      ) => {
        if (acc[version_number]) {
          acc[version_number].status.push(status);

          acc[version_number].target_type.push(target_type ?? "--");
          acc[version_number].last_modified_by.push(last_modified_by);

          acc[version_number].modified_at.push({
            date: getDateTime(modified_at).date,
            time: getDateTime(modified_at).time,
          });
          acc[version_number].actions.push({ restore: true, view: true });
          acc[version_number].actions.push({ restore: false, view: false });
        } else {
          acc[version_number] = {
            id,
            version_number: version_number.toString(),
            status: [status],
            target_type: [target_type ?? "--"],
            target_id,
            last_modified_by: [last_modified_by],
            modified_at: [
              {
                date: getDateTime(modified_at).date,
                time: getDateTime(modified_at).time,
              },
            ],
            actions: [{ restore: true, view: true }],
          };
        }
        return acc;
      },
      {}
    )
  );
}

export function Activity({
  target_id,
  hiddenColumns,
  mode,
}: {
  target_id: string;
  hiddenColumns?: string[];
  mode: "template" | "product";
}) {
  const perPageItems = [10, 20, 50];
  const [tableData, setTableData] = useState<ActivityTableData[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [offset, setOffset] = useState(0);
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const { data: productActivity, error: productActivityError } = useSWR<
    WithPagination<{ data: PIMProductActivity[] }>
  >([
    endpoints.v2_storefronts_id_pim_actvities(tenant_id),
    useMemo(
      () => ({
        params:
          mode === "template"
            ? {
                template_id: target_id,
                limit: perPage,
                offset,
                order_by: sortingRules.orderBy || "asc",
                sort_by: sortingRules.sortBy || "modified_at",
              }
            : {
                product_id: target_id,
                limit: perPage,
                offset,
                order_by: sortingRules.orderBy || "asc",
                sort_by: sortingRules.sortBy || "modified_at",
              },
      }),
      [
        mode,
        offset,
        perPage,
        sortingRules.orderBy,
        sortingRules.sortBy,
        target_id,
      ]
    ),
  ]);

  const isLoading = !productActivity && !productActivityError;

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const tableColumns = React.useMemo(
    () => [
      {
        header: t("Version"),
        accessorKey: "version_number",
        enableSorting: false,
        cell: ({ getValue }: { getValue: Getter<string> }) => (
          <RowBox>{getValue()}</RowBox>
        ),
      },
      {
        header: t("Last Modified"),
        accessorKey: "modified_at",
        enableSorting: false,
        cell: ({ getValue }: { getValue: Getter<ModifiedType[]> }) => {
          return getValue().map(({ date, time }) => (
            <RowBox key={time + date} style={{ gap: "16px" }}>
              <div>{date}</div>
              <div>{time}</div>
            </RowBox>
          ));
        },
      },
      {
        header: t("Modified"),
        accessorKey: "target_type",
        enableSorting: false,
        cell: ({ getValue }: { getValue: Getter<string[]> }) => {
          return getValue().map((target_type, idx) => (
            <RowBox key={target_type + idx}>{target_type}</RowBox>
          ));
        },
      },
      {
        header: t("Last Modified By"),
        accessorKey: "last_modified_by",
        enableSorting: false,
        cell: ({ getValue }: { getValue: Getter<string[]> }) => {
          return getValue().map((last_modified_by, index) => (
            <RowBox key={last_modified_by + index}>{last_modified_by}</RowBox>
          ));
        },
      },
      {
        header: t("Status"),
        accessorKey: "status",
        enableSorting: false,
        align: "left",
        cell: ({ getValue }: { getValue: Getter<ProductStatusType[]> }) => {
          return getValue().map((status, index) => {
            const colorKey = getProductStatusColor(status);
            const textKey = getProductStatusText(status, t);
            return (
              <RowBox key={status + index}>
                <StatusLeft color={colorKey} text={""} />
                <span>{textKey}</span>
              </RowBox>
            );
          });
        },
      },
    ],
    [t]
  );

  useEffect(() => {
    const handleData = ({
      data,
      pagination,
    }: WithPagination<{ data: PIMProductActivity[] }>) => {
      const activities = transformData(data);
      setTableData(activities);
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };
    if (productActivity) {
      handleData(productActivity);
    }
  }, [perPage, productActivity]);

  return (
    <ContentWrapper>
      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
      </DropdownContainer>
      <Table
        columns={tableColumns}
        data={tableData}
        isLoading={isLoading}
        error={productActivityError}
        handleSort={handleSort}
        hiddenColumns={hiddenColumns}
        lastChildleftAlign
      />
      <Pagination
        pagination={tablePagination}
        offset={offset}
        handlePageClick={changePage}
      />
    </ContentWrapper>
  );
}
