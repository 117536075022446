import { useContext } from "react";
import type { DeepMap, FieldError, FormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { InputActionMeta, MenuPlacement } from "react-select";
import Select, { components } from "react-select";
import { ThemeContext } from "styled-components/macro";
import type { OptionType } from "../../types/types";
import { filterOption } from "../../util/util";
import { showErrors } from "../../util/util-components";
import {
  CustomOption,
  CustomValueContainer,
  DropdownIndicator,
  Menu,
} from "../SelectBoxShared/SelectBoxShared";
import { hasValue } from "./SelectBoxV2.utils";

/* 
  This cannot be called by itself, only as a controlled input inside of a <form>
  managed by react-hook-form.  See CreateNewOffer for a working example.

          <Controller
            as={SelectBoxV2}
            control={control}
            name="product"
            placeholder="Product Name"
            options={products}
            rules={{ required: true }}
            errors={errors}
            formState={formState}
          />
*/

export interface SelectBoxProps {
  formState: FormState<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  name: string;
  options: OptionType[];
  placeholder: string;
  defaultValue?: OptionType;
  isSearchBar?: boolean;
  menuPlacement?: MenuPlacement;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  setDefaultTerm?: boolean;
  normalPlaceholder?: boolean;
  isCreatable?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  [x: string]: any;
  error?: FieldError;
}

export function SelectBoxV2({
  formState,
  errors,
  name,
  isSearchBar,
  onInputChange,
  menuPlacement = "auto",
  normalPlaceholder,
  disabled,
  isSearchable,
  isClearable,
  error,
  ...rest
}: SelectBoxProps) {
  const { submitCount } = formState;
  const styledTheme = useContext(ThemeContext);
  const { t } = useTranslation();
  const { placeholder } = rest;

  const Dropdown = isSearchBar
    ? DropdownIndicator
    : components.DropdownIndicator;

  const hasError = errors[name] || error;

  return (
    <div style={{ position: "relative" }} data-testid={placeholder}>
      <Select
        className="basic-single"
        isSearchable={isSearchable}
        classNamePrefix="select"
        menuPlacement={menuPlacement}
        onInputChange={onInputChange}
        isDisabled={disabled}
        isClearable={isClearable}
        filterOption={filterOption}
        components={{
          ValueContainer: CustomValueContainer,
          IndicatorSeparator: () => null,
          Menu: Menu,
          DropdownIndicator: Dropdown,
          Option: CustomOption,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary:
              submitCount > 0 && hasError
                ? styledTheme.errorColor
                : styledTheme.tertiaryBG,
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            ...(submitCount > 0 &&
              hasError && {
                border: `2px solid ${styledTheme.errorColor}`,
                "&:hover": {
                  border: `2px solid ${styledTheme.errorColor}`,
                },
              }),
            height: 52,
            minHeight: 52,
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            color:
              submitCount > 0 && hasError ? styledTheme.errorColor : "#808080",
          }),
          option: (base, state) => ({
            ...base,
            color: styledTheme.primaryTextColor,
            fontSize: styledTheme.fontSizes.medium,
            backgroundColor:
              /** Compare labels (since it's always unique)
               * Instead of using state.isSelected
               * There seems to be a bug with state.isSelected implementation, where multiple
               * options fulfill this condition, although one value is actually selected.
               * This should fix that.
               */
              state?.selectProps?.value?.label === state.label
                ? styledTheme.secondaryButtonBG
                : styledTheme.primaryBG,
            ":hover": {
              backgroundColor: styledTheme.secondaryButtonBG,
            },
          }),
          valueContainer: (provided: any, state: any) => ({
            ...provided,
            overflow: "visible",
            fontSize: styledTheme.fontSizes.medium,
            marginTop: state.isSelected ? "0px" : "9px",
          }),
          placeholder: (provided: any, state: any) => ({
            ...provided,
            position: normalPlaceholder ? "relative" : "absolute",
            top:
              hasValue(state.selectProps.value) || state.selectProps.inputValue
                ? 2
                : "40%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize:
              hasValue(state.selectProps.value) || state.selectProps.inputValue
                ? styledTheme.fontSizes.xs
                : styledTheme.fontSizes.medium,
            marginBottom: 2,
            display:
              normalPlaceholder &&
              (hasValue(state.selectProps.value) ||
                state.selectProps.inputValue)
                ? "none"
                : "block",
            color:
              submitCount > 0 && hasError ? styledTheme.errorColor : "#808080",
          }),
        }}
        {...rest}
      />
      {showErrors({ errors, error, name, t })}
    </div>
  );
}
